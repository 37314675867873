z<template>
  <div v-loading="true" style="width: 100%; height: 100%"></div>
</template>
<script>

export default {
  mounted() {
    this.$store.dispatch('user/checkSession').finally(() => this.$router.push(this.$route.query.redirect))
  }
}
</script>

